import { ChargingErrorMessageService } from "@/features/charging/chargingAlertMService";
import {
  ChargeToRequest,
  ChargingData,
  ChargingState,
  ChargingStatusFromServer,
  GetChargeToRequest,
  SetCodeRequest,
  UnitData,
} from "@/models/charging";
import { ResponseData } from "@/models/common";
import router, { APP_ROUTE } from "@/router";
import { AppMessageService } from "@/utils/alert/alertService";
import { logInfo } from "@/utils/alert/logService";
import { HttpHelper } from "@/utils/http/httpHelper";
import { StorageService } from "@/utils/storage/storage";
import { dateTimeHelper } from "@/utils/types/dateTime";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import Vue from "vue";
import { QRCodeScannerService } from "./qrCodeScannerService";


const CHARGING_CODE = "ChargingPointConnectorCode";
const CHARGING_UNIT_DATA = "ChargingUnitData";
const DEFAULT_CHARGE_UNTIL = 80;
const INIT_CHARGE = 80;

const initState: ChargingState = {
  pointConnectorCode: null,
  chargingInitAnimationValue: 0,
  unitData: {
    UnitName: "",
    UnitId: "",
    chargeCode: null,
  },
  setCodePending: false,
  startChargingTransactionPending: false,
  chargingData: {
    totalDelivered: 0,
    elapsedTime: 0,
    MeterValue: 0,
    Status: null,
    ChargingErrorCode: null,
    Price: null,
  },

  statusDataLoading: false,

  stopChargingTransactionPending: false,

  chargeUntil: DEFAULT_CHARGE_UNTIL,
  chargeToPending: false,
  chargeToLoading: false
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const ChargingService = {
  get getState() {
    return state;
  },
  
  async getChargingDataFromStorage() {
    try {
      state.pointConnectorCode = await StorageService.get(CHARGING_CODE);
      state.unitData = await StorageService.get(CHARGING_UNIT_DATA, true);
    } catch (error: any) {
      // console.log(error);
    }
  },
  get getElapsedTime(): number {
    return (state.chargingData || {}).elapsedTime || 0;
  },
  get getElapsedTimeFormatted(): string {
    const time = ChargingService.getElapsedTime
        ? (dateTimeHelper.getTimeFromSeconds(ChargingService.getElapsedTime) || "-")
        : "";
      return time;
  },
  get getTotalDelivered(): number {
    //TotalDelivered er i Wh, skal du ha kWh så må du dele på 1000
    const value = (state.chargingData || {}).totalDelivered;
    try {
      const kWH = value && value != 0 ? value / 1000 : 0;
      const kWHRounded = Math.round(kWH);
      return kWHRounded;
    } catch (err) {
      AppMessageService.showError(
        `Could not convert value: ${value} from Wh to kWH. Error message: ${err}`
      );

      return 0;
    }
  },
  get getMeterValue(): number {
    let result = 0;
    if (state.chargingData) {
      result = state.chargingData.MeterValue;
    }
    if (result > 100) result = 100;
    if (result < 0) result = 0;
    return result;
  },
  get getPointConnectorCode() {
    return state.pointConnectorCode;
  },
  get getChargingInitAnimationValue() {

      setTimeout(()=> {

        // console.log('state.chargingInitAnimationValue: ' +  state.chargingInitAnimationValue);

        if(state.chargingInitAnimationValue <= INIT_CHARGE ){
          
          state.chargingInitAnimationValue = state.chargingInitAnimationValue + 1;
        }
      }, 100)

    return state.chargingInitAnimationValue < INIT_CHARGE? state.chargingInitAnimationValue: INIT_CHARGE;
  },
  get getChargeCode() {
    return state.unitData ? state.unitData.chargeCode : null;
  },
  get getPrice(){
    return (state.chargingData || {}).Price || "";
  },
  async setChargingCode(value: any) {
    state.pointConnectorCode = value;
    await StorageService.set(CHARGING_CODE, value);
  },
  async deleteChargingCode() {
    state.pointConnectorCode = null;
    await StorageService.removeStorageItem(CHARGING_CODE);
  },
  get getChargingStatus() {
    return (state.chargingData || {}).Status || null;
  },
  setChargingStatus(status: ChargingStatusFromServer | null) {
    if (state.chargingData) {
      state.chargingData.Status = status;
    }
  },
  deleteChargingStatus() {
    if (state.chargingData) {
      state.chargingData.Status = null;
      state.chargingData.ChargingErrorCode = null;
    }
  },
  get getUnitData(): UnitData | null {
    return state.unitData;
  },
  async setUnitData(value: UnitData) {
    state.unitData = value;
    await StorageService.set(CHARGING_UNIT_DATA, value, true);
  },
  async deleteUnitData() {
    state.unitData = null;
    await StorageService.removeStorageItem(CHARGING_UNIT_DATA);
  },
  async deleteAllChargingData() {
    await this.deleteUnitData();
    await this.deleteChargingCode();
    this.deleteChargingStatus();
  },
  get getChargeUntil() {
    return state.chargeUntil;
  },
  setChargeUntil(value: any) {
    state.chargeUntil = value;
  },
  async resetChargingData() {
    await this.deleteAllChargingData();
    state.pointConnectorCode = null;
    state.unitData = {
      UnitName: "",
      UnitId: "",
      chargeCode: null,
    };
    this.resetChargingDataObj();
    state.setCodePending = false;
    state.startChargingTransactionPending = false;
    state.statusDataLoading = false;
    state.stopChargingTransactionPending = false;
    state.chargeUntil = DEFAULT_CHARGE_UNTIL;
    state.chargeToPending = false;
    state.chargeToLoading = false;
    state.chargingInitAnimationValue = 0;
  },
  resetChargingDataObj() {
    const chargingData: ChargingData = {
      totalDelivered: 0,
      elapsedTime: 0,
      MeterValue: 0,
      Status: null,
      ChargingErrorCode: null,
      Price: null
    };
    state.chargingData = chargingData;
  },

  /** doc: https://github.com/capacitor-community/barcode-scanner/tree/73db101b9a62bc31595f469ad4c5c4cc772fdc1b*/
  /** A more detailed and more UX-optimized example, from docs. */
  async didUserGrantPermission() {
    // check if user already granted permission
    const status = await BarcodeScanner.checkPermission({ force: false });

    if (status.granted) {
      // user granted permission
      return true;
    }

    if (status.denied) {
      // user denied permission
      AppMessageService.showError(
        "Permission to use your camera is denied, so we can not scan barcodes"
      );
      return false;
    }

    if (status.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (status.neverAsked) {
      // user has not been requested this permission before
      // it is advised to show the user some sort of prompt
      // this way you will not waste your only chance to ask for the permission
      const c = confirm(
        "We need your permission to use your camera to be able to scan barcodes"
      );
      if (!c) {
        return false;
      }
    }

    if (status.restricted || status.unknown) {
      // ios only
      // probably means the permission has been denied
      AppMessageService.showError(
        "Permission to use your camera has been denied"
      );
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({
      force: true,
    });

    if (statusRequest.asked) {
      // system requested the user for permission during this call
      // only possible when force set to true
    }

    if (statusRequest.granted) {
      // the user did grant the permission now
      return true;
    }

    // user did not grant the permission, so he must have declined the request
    AppMessageService.showError(
      "We are sorry to inform you that you did not grant the permission, so he must have declined the request"
    );
    return false;
  },

  startScan() {
    QRCodeScannerService.startScan().then((code) => {
      this.setChargingCode(code);
      const newCodeReq: SetCodeRequest = { newCode: code };
      this.requestSetCode(newCodeReq);
    });
  },

  doRetryScan(): any {
    AppMessageService.close();
    QRCodeScannerService.setIsRetryingToActive();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const objThis = this;

    setTimeout(() => {
      objThis.startScan();
    }, 500);
  },

  async requestSetCode(args: SetCodeRequest): Promise<any> {
    state.setCodePending = true;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const thisObj = this;

    if (!args || !args.newCode) {
      AppMessageService.showError("newCode is missing", "redText");
      return;
    }

    return HttpHelper.httpRequestHandler({
      url: "/SetCode",
      withDelay: 1000,
      method: "POST",
      data: args,
    })
      .then(async (res) => {

        state.setCodePending = false;
        thisObj.remoteStartTransactionRequest();
      })
      .catch(async (res) => {
        state.setCodePending = false;
        QRCodeScannerService.setIsRetryToActive();
        throw res;
      });
  },

  async remoteStartTransactionRequest() {
    const newCode = this.getPointConnectorCode;

    if (!newCode) {
      AppMessageService.showError("Code missing", "redText");
      return Promise.reject();
    }

    state.startChargingTransactionPending = true;

    const dataToSend: SetCodeRequest = {
      newCode,
    };

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const objThis = this;

    return HttpHelper.httpRequestHandler({
      url: "/RemoteStartTransaction",
      method: "POST",
      data: dataToSend,
    })
      .then(async (res: ResponseData) => {
        const resultData = res.dataList || {};

        // console.log("RemoteStartTransaction: ");
        // console.log(resultData);
        // console.log(res.dataList);

        const unitData = resultData[0] as UnitData;
        objThis.setUnitData(unitData);
        AppMessageService.close();
        // console.log("unitData");
        // console.log(unitData);
        state.startChargingTransactionPending = false;

        router.push(`/${APP_ROUTE.charging.path}`);
      })
      .catch(async (res) => {
        state.startChargingTransactionPending = false;
        QRCodeScannerService.setIsRetryToActive();
        throw res;
      });
  },

  async stopChargingRequest(): Promise<any> {
    const newCode = this.getPointConnectorCode;

    if (!newCode) {
      return Promise.reject();
    }

    state.stopChargingTransactionPending = true;

    const dataToSend: SetCodeRequest = {
      newCode,
    };

    return HttpHelper.httpRequestHandler({
      url: "/RemoteStopTransaction",
      method: "POST",
      data: dataToSend,
      withDelay: 1000,
    })
      .then(async () => {
        AppMessageService.close();
        router.push(`/${APP_ROUTE.chargingSummary.path}`);
        state.stopChargingTransactionPending = false;

      })
      .catch(async () => {
        state.stopChargingTransactionPending = false;
      });
  },
  
  setStateData() {
    
    const item = {
      Status: 'Finish',
      elapsedTime: 500,
      MeterValue: 30,
      totalDelivered: 3000
    };
    state.chargingData = item as ChargingData;
  },

  async requestStatusData(code: any): Promise<any> {
    state.statusDataLoading = true;
    if (!code) {
      AppMessageService.showError("Code missing", "redText");

      return Promise.reject();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-this-alias
    const objThis = this;
    ChargingErrorMessageService.close();

    return HttpHelper.httpRequestHandler({
      url: `/GetStatusData?ChargeCode=${code}`,
      alertHelpers: ChargingErrorMessageService,
    })
      .then((res: ResponseData) => {
        // console.log("requestStatusData:");
        // console.log(res);
        // eslint-disable-next-line prefer-const
        let chargingData: ChargingData | null =
          res.dataList && res.dataList[0] ? res.dataList[0] : null;

        /*
        (chargingData as ChargingData).Status = 'Charging';
        (chargingData as ChargingData).elapsedTime = 500;
        (chargingData as ChargingData).MeterValue = 60;
        (chargingData as ChargingData).totalDelivered = 30000;
        */
        
        if (!chargingData) {
          // console.log("! charging data");
          this.resetChargingDataObj();

        } else if (chargingData && (chargingData as ChargingData).Status === 'Finish') {
          // console.log("chargingData.Finish == true");

          state.stopChargingTransactionPending = true;
          state.chargingData = chargingData;
          router.push(`/${APP_ROUTE.chargingSummary.path}`);
          state.stopChargingTransactionPending = false;


        } else {
          state.chargingData = chargingData;
        }
        return chargingData;
      })
      .finally(() => {
        state.statusDataLoading = false;
      });
  },

  async requestChargeToData() {
    state.chargeToLoading = true;

    return HttpHelper.httpRequestHandler({
      url: "/ChargeTo",
    })
      .then((res: ResponseData) => {
        state.chargeUntil =
          res.dataList && res.dataList.length > 0
            ? (res.dataList[res.dataList.length - 1] as GetChargeToRequest)
                .ChargeTo
            : DEFAULT_CHARGE_UNTIL;
      })
      .finally(() => {
        state.chargeToLoading = false;
      });
  },

  async updateChargeToRequest(value: number): Promise<any> {
    state.chargeToPending = true;
    const prevValue = state.chargeUntil;

    if (!value) {
      AppMessageService.showError("Missing chargeTo", "redText");
      return Promise.reject();
    }

    const dataToSend: ChargeToRequest = {
      chargeTo: value,
    };

    return HttpHelper.httpRequestHandler({
      url: "/ChargeTo",
      method: "POST",
      data: { data: dataToSend },
      withDelay: 1000,
    })
      .then((res) => {
        logInfo({ info: "ChargeToRequest success" });
        // console.log(res);
        ChargingService.setChargeUntil(value);
      })
      .catch((err) => {
        logInfo({ info: "ChargeToRequest failed", type: "e" });
        // console.log(err);

        ChargingService.setChargeUntil(prevValue + 1);
        ChargingService.setChargeUntil(prevValue);
      })
      .finally(() => {
        state.chargeToPending = false;
      });
  },
};
