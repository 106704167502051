import { AppCountry, CountryAppState } from "@/models/country";
import { HttpHelper } from "@/utils/http/httpHelper";
import Vue from "vue";

const initState: CountryAppState = {
  countries: [],
  countriesLoading: true,
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const CountryService = {
  get countriesState() {
    return state;
  },
  get getCountries() {
    return state.countries;
  },
  createNewObj() {
    const newUserObj: AppCountry = {
      Description: null,
      CountryId: null,
    };
    return newUserObj;
  },
  setLoading(value = false) {
    state.countriesLoading = value;
  },

  async requestCountries() {
    state.countriesLoading = true;

    return HttpHelper.httpRequestHandler({
      url: "/code/GetCountryCode",
    })
      .then((res) => {
        state.countries = res.dataList;
      })
      .finally(() => {
        state.countriesLoading = false;
      });
  },
};
