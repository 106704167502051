import { Preferences } from "@capacitor/preferences";
import { AppMessageService } from "../alert/alertService";

/* local storage is used under development. Native storage is used in prod.  */
const storageServ = () => {
  const set = async (storageKey: string, value: any, isStringify = false) => {
    const val = isStringify ? JSON.stringify(value) : value;

    try {
      if (process.env.NODE_ENV === "development") {
        localStorage.setItem(storageKey, val);
      } else {
        await Preferences.set({
          key: storageKey,
          value: val,
        });
      }
    } catch (error) {
      // console.log(error);
      // todo use alert service
      AppMessageService.showError("Problem with storage");
    }
  };

  const get = async (storageKey: string, isParse = false) => {
    try {
      if (process.env.NODE_ENV === "development") {
        const data = localStorage.getItem(storageKey);
        return isParse && typeof data === "string" ? JSON.parse(data) : data;
      } else {
        const ret: any = await Preferences.get({ key: storageKey });
        return isParse ? JSON.parse(ret.value) : ret.value;
      }
    } catch (error) {
      // console.log(error);
      AppMessageService.showError("Problem with storage");
    }
  };

  const removeStorageItem = async (storageKey: string) => {
    try {
      if (process.env.NODE_ENV === "development") {
        await localStorage.removeItem(storageKey);
      } else {
        await Preferences.remove({ key: storageKey });
      }
    } catch (error) {
      // console.log(error);
      AppMessageService.showError("Problem with storage");
    }
  };

  // Clear storage
  const clear = async () => {
    try {
      if (process.env.NODE_ENV === "development") {
        await localStorage.clear();
      } else {
        await Preferences.clear();
      }
    } catch (error) {
      // console.log(error);
      AppMessageService.showError("Problem with storage");
    }
  };

  return {
    set,
    get,
    removeStorageItem,
    clear,
  };
};

export const StorageService = storageServ();
