import { AppState } from "@/models/app";
import router, { AppRouteName, APP_ROUTE } from "@/router";
import Vue from "vue";

const initState: AppState = {
  currentTab: null,
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const AppService = {
  get getCurrentTab() {
    return state.currentTab;
  },
  setCurrentTab(value: AppRouteName | null) {
    state.currentTab = value;
  },
  chooseCurrentTab(){
    if (router.currentRoute.name === APP_ROUTE.profile.name){
      state.currentTab = APP_ROUTE.profile.name as AppRouteName;
    }
    else if (router.currentRoute.name === APP_ROUTE.support.name){
      state.currentTab = APP_ROUTE.profile.name as AppRouteName;
    }
    else {
      state.currentTab = APP_ROUTE.chargeCode.name as AppRouteName;
    }
  }
};
