import { AppInfoAppState } from "@/models/app";
import { HttpHelper } from "@/utils/http/httpHelper";
import Vue from "vue";

const initState: AppInfoAppState = {
  supportInfo: null,
  supportInfoLoading: true,
};

const state = Vue.observable(initState);

export const AppInfoService = {
  get appInfoState() {
    return state;
  },
  get getSupportInfo() {
    return state.supportInfo;
  },
  setLoading(value = false) {
    state.supportInfoLoading = value;
  },
  getLoading(){
    return state.supportInfoLoading;
  },
  async requestSupportInfo() {
    state.supportInfoLoading = true;

    return HttpHelper.httpRequestHandler({
      url: "App_Control/GetSupportInfo",
    })
      .then((res) => {
        state.supportInfo = res && res.dataList ? res.dataList[0] : {};
      })
      .finally(() => {
        state.supportInfoLoading = false;
      });
  },
};
