
import { APP_ROUTE } from "@/router";
import { ChargingService } from "@/services/chargingService";
import { QRCodeScannerService } from "@/services/qrCodeScannerService";
import { UserService } from "@/services/userService";
import { ValidationService } from "@/utils/form/validationHelpers";
import Vue from "vue";

export default Vue.extend({
  name: "ChargeCode",
  components: {
    spinner: () => import("../components/spinner.vue"),
    errorMessage: () => import("../components/errorMessage.vue"),
  },

  data() {
    return {
      rules: ValidationService.getRules(),
      fetchingChargeCode: true
    };
  },
  async created() {

    await UserService.requestUser();

    await ChargingService.getChargingDataFromStorage();

    const pointConnectorCode = ChargingService.getPointConnectorCode;
    const chargingState = ChargingService.getChargingStatus;
    const chargeCode = ChargingService.getChargeCode;

    if (chargeCode) {
      this.$router.push(`/${APP_ROUTE.charging.path}`);

    } else {
      this.fetchingChargeCode = false;

      if (pointConnectorCode) {
        // clean up data in this case
        ChargingService.resetChargingData();

        // console.log("state after cleaning");
        // console.log(JSON.stringify(ChargingService.getState));
      }

      //this.changeAppBgColor();
      QRCodeScannerService.hideBg();

      let result = await QRCodeScannerService.didUserGrantPermission();

      if (result) {
        ChargingService.startScan();
      }
    }

    window.scrollTo(0, 0);
  },
  computed: {
    fetchingChargeCodeInfo(){
      return this.fetchingChargeCode;
    },
    userLoading() {
      return UserService.userState.userLoading;
    },
    statusDataLoading(){
      return ChargingService.getState.statusDataLoading;
    },
    retrying() {
      return QRCodeScannerService.isRetrying;
    },
    isRetryQRScan() {
      return QRCodeScannerService.isRetry;
    },
    chargeCode() {
      return ChargingService.getPointConnectorCode;
    },
    startChargingPending() {
      return ChargingService.getState.setCodePending || ChargingService.getState.startChargingTransactionPending;
    },
  },
  methods: {
    goToPageWithInput(){
      this.$router.push(`/${APP_ROUTE.chargeCodeWithInput.path}`);
    },
    retryScan() {
      ChargingService.doRetryScan();
    }
  },
  beforeDestroy() {
    QRCodeScannerService.stopScan();
  },
  destroyed() {
    // console.log("destoyed");
  },
});
