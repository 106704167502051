
import { CountryService } from "@/services/countryService";
import { SecurityService } from "@/services/securityService";
import { UserService } from "@/services/userService";
import Vue from "vue";

export default Vue.extend({
  name: "ProfilePage",
  components: {
    updateUserForm: () => import("../features/user/updateUserForm.vue"),
    confirmDialog: () => import("../components/confirmDialog.vue"),
  },
  data: () => ({
    dialog: false,
  }),
  created() {
    if (!UserService.userState.user) {
      UserService.requestUser();
    }

    if (
      !CountryService.getCountries ||
      CountryService.getCountries.length <= 0
    ) {
      CountryService.requestCountries();
    }


    window.scrollTo(0, 0);
  },
  destroyed() {
    UserService.clearUser();
  },
  computed: {
    deletingUser() {
      return UserService.userState.userDeleting;
    },
    userLoading() {
      return UserService.userState.userLoading;
    },
    getUserEmail() {
      return UserService.getUser?.EMail;
    },
    emailIsLong() {
      return UserService.getUser &&
        UserService.getUser.EMail &&
        UserService.getUser.EMail.length > 26
        ? true
        : false;
    },
  },
  methods: {
    deleteProfile() {
      this.dialog = true;
    },
    onConfirmProfileDeleting() {
      UserService.deleteUserRequest().finally(this.onConfirmProfileDeletingDo);
    },
    onConfirmProfileDeletingDo() {
      this.dialog = false;
    },
    onCancelProfileDeleting() {
      this.dialog = false;
    },
    clickOutsideConfirmDialog() {
      this.dialog = false;
    },
    logout() {
      SecurityService.logout();
    },
  },
});
