const JWTTokenName = "user-token";
const JWTTokenUserData = "elview-user-data";

const DefaultCountryId = 30; // Norway

export type MessageType = "redText" | "error" | "success" | "message" | null;
export interface HowToShowErrorInterface {
  [key: string]: MessageType;
}

export const HowToShowError: HowToShowErrorInterface = {
  redText: "redText",
  error: "error",
  message: "message",
};

/** app error code returned from server */
const AppErrorCode = {
  userNotRegistered: {
    name: "errorUserIsNotRegistered",
    howToShow: HowToShowError.error,
  },
  errorIncorrectUserVerificationCode: {
    name: "errorIncorrectUserVerificationCode",
    howToShow: HowToShowError.redText,
  },
  userIsDeactivated: {
    name: "erroUserIsDeactivated",
    howToShow: HowToShowError.error,
  },
  registrationTimeOut: {
    name: "RegistrationTimeOut",
    howToShow: HowToShowError.error,
  },
  missingAcceptedTerms: {
    name: "missingAcceptedTerms",
    howToShow: HowToShowError.error,
  },
  nonExistingCharger: { 
    name: "nonExistingCharger",
    howToShow: HowToShowError.error,
  },
  errorUserIsNotRegistred: { 
    name: "errorUserIsNotRegistred",
    howToShow: HowToShowError.error,
  },
  errorWrongUserNameOrPassword: {
    name: "errorWrongUserNameOrPassword",
    howToShow: HowToShowError.error,
  },
  error404: { name: "error404", howToShow: HowToShowError.error },
  error500: { name: "error500", howToShow: HowToShowError.error },
};

export const getAppErrorCodeType = (
  keyStr: string
): { name: string; howToShow: MessageType } | null => {
  for (const [key, value] of Object.entries(AppErrorCode)) {
    if (key === keyStr) {
      return value;
    }
  }
  return null;
};

const ThemeColors = {
  primary: "#53B6B3", //Blue
  secondary: "#69CA97", //Green
  accent: "#F6CC6A", //Yellow
  error: "#AEB6B6", // Grey
  warning: "#FF7064", //Red
};

const ErrNetwork = 'ERR_NETWORK';

export const Constants = {
  JWTTokenName,
  JWTTokenUserData,
  AppErrorCode,
  DefaultCountryId,
  ThemeColors,
  ErrNetwork
};
