
import { AppInfoService } from "@/services/appInfoService";
import { App } from '@capacitor/app';
import Vue from "vue";

export default Vue.extend({
  name: "Support",
  components: {
    spinner: () => import("../components/spinner.vue"),
  },
  data: () => ({
    version: "2.2.3",
  }),
  created() {
    window.scrollTo(0, 0);

    if (App) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      App.getInfo().then((info) => {
        if (info && info.version) {
          self.version = info.version;
        }
      });
    }

    AppInfoService.requestSupportInfo();
  },
  computed: {
    dataLoading() {
      return AppInfoService.getLoading;
    },
    email() {
      return (
        (AppInfoService.getSupportInfo || {}).email || "support@eldrift.no"
      );
    },
    phone() {
      return (
        (AppInfoService.getSupportInfo || {}).telephone || "+47 51 31 51 61"
      );
    },
  },
});
