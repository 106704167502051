import { Constants } from "@/constants";
import { AppAuthUser } from "@/models/auth";
import { AppNewUser, AppUser, AppUserRole, UserAppState } from "@/models/user";

import router, { APP_ROUTE, goToMainPage } from "@/router";
import { AppMessageService } from "@/utils/alert/alertService";
import { HttpHelper } from "@/utils/http/httpHelper";
import Vue from "vue";
import { OneSignalService } from "./oneSignalService";
import { SecurityService } from "./securityService";

const initState: UserAppState = {
  user: null,
  userSaving: false,
  userLoading: true,
  userDeleting: false,
  userCancelingChanges: false,
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const UserService = {
  get userState() {
    return state;
  },
  get getUser() {
    return state.user;
  },
  get userHasSettingsAccess() {
    const isAdmin = state.user && (state.user.UserLevel == AppUserRole.Admin || state.user.UserLevel == AppUserRole.CsOperator || state.user.UserLevel == AppUserRole.ElchargeSuperuser || state.user.UserLevel == AppUserRole.Support) ? true: false;
    return isAdmin;
  },
  createNewUserObj() {
    // console.log("createNewUserObj");
    const newUserObj: AppNewUser = {
      LastName: null,
      FirstName: null,
      EMail: null,
      Mobile: null,
      Address: null,
      PostCode: null,
      City: null,
      CountryID: Constants.DefaultCountryId,
      AcceptEInvoice: false,
      AcceptedTerms: false,
      AcceptedPromotions: false,
    };
    return newUserObj;
  },
  clearUser() {
    // console.log("clearUser");

    state.user = null;
    state.userLoading = false;
    state.userDeleting = false;
    state.userSaving = false;
  },
  setUserLoading(value = false) {
    state.userLoading = value;
  },
  setUserData(value: AppUser) {
    // console.log("setUserData");
    // console.log(value);
    state.user = value;
  },

  async requestUser() {
    state.userLoading = true;

    return HttpHelper.httpRequestHandler({
      url: "/ViewAppUserDataWithCompany/GetViewAppUserDataWithCompany",
      withDelay: 200,
    })
      .then((res) => {
        state.user = res.dataList;
      })
      .finally(() => {
        state.userLoading = false;
      });
  },

  async addUserRequest(data: AppNewUser) {
    state.userSaving = true;
    const dataToSend = { data };

    return HttpHelper.httpRequestHandler({
      url: "/App_Control/DoSaveNew",
      method: "POST",
      data: dataToSend,
      withDelay: 1000,
    })
      .then((res: any) => {
        const resultData = res.dataList as AppAuthUser;
        const userId = resultData ? resultData.UserID : null;
        // set token
        SecurityService.setTokenAndUserData(resultData);

        if (process.env.NODE_ENV !== "development") { 

          // console.log('PushNotificationExternalUserIdIsNotSent');

          if (!userId) {
            AppMessageService.showError('PushNotificationExternalUserIdIsNotSent');
            throw 'Push notification external user is not configured';
          }
          
          OneSignalService.configureExternalUserId(userId.toString()); 

          OneSignalService.promptForPushNotifications(() => {
            // redirect to main page.
           goToMainPage();
         });

        }
        else {
          goToMainPage();
        }
        AppMessageService.close();
      })
      .finally(() => {
        state.userSaving = false;
      });
  },

  async updateUserRequest(user: AppUser) {
    state.userSaving = true;
    const dataToSend = { data: user };

    return HttpHelper.httpRequestHandler({
      url: "/App_Control/DoSaveUpdate",
      method: "POST",
      data: dataToSend,
      withDelay: 1000,
    })
      .then((res) => {
        // console.log(res);
        goToMainPage();
      })
      .finally(() => {
        state.userSaving = false;
      });
  },

  async deleteUserRequest() {
    state.userDeleting = true;

    return HttpHelper.httpRequestHandler({
      url: "/AppUser/DeleteProfile",
      method: "DELETE",
      withDelay: 1000,
    })
      .then(() => {
        SecurityService.logout();
        // console.log('delete user');
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      })
      .finally(() => {
        state.userDeleting = false;
      });
  },
};
