
import { UserService } from "@/services/userService";
import { AppService } from "@/services/appService";
import { AppRouteName, APP_ROUTE } from "@/router";
import Vue from "vue";
import { App } from "@capacitor/app";

export default Vue.extend({  
  name: "Auth2Factor",
  components: {
    spinner: () => import("../components/spinner.vue"),
  },
  data: () => ({
      version: ''
  }),
  created() {
    UserService.requestUser();

    window.scrollTo(0, 0);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    if (App) {
      App.getInfo().then((info) => {
        if (info && info.version) {
          self.version = info.version;
        }
      });
    }
  },
  computed: {
    userHasSettingsAccess(){
      return UserService.userHasSettingsAccess;
    },
    userLoading() {
      return UserService.userState.userLoading;
    },
  },
  methods: {
    myProfile() {
      this.$router.push(`/${APP_ROUTE.profile.path}`);
    },
    help() {
      this.$router.push(`/${APP_ROUTE.support.path}`);
    },
    PortableCharger() {
      this.$router.push("/PortableCharger");
    },
    ElStore() {
      this.$router.push("/ELStore");
    },
    startLading() {
      AppService.setCurrentTab(APP_ROUTE.chargeCode.name as AppRouteName);
      this.$router.push(`/${APP_ROUTE.chargeCode.path}`);
    },
    Auth2Factor() {
      this.$router.push("/Auth2Factor");
    }
  },
});
