
import { HowToShowError } from "@/constants";
import { ChargingErrorMessageService } from "@/features/charging/chargingAlertMService";
import { APP_ROUTE } from "@/router";
import { ChargingService } from "@/services/chargingService";
import { ValidationService } from "@/utils/form/validationHelpers";
import Vue from "vue";

export default Vue.extend({
  name: "ChargingPage",
  data: () => ({
    timerId: 0,
    rules: ValidationService.getRules(),
  }),
  components: {
    spinner: () => import("../components/spinner.vue"),
    errorMessage: () => import("../components/errorMessage.vue"),
  },
  async created() {
    await ChargingService.getChargingDataFromStorage();

    const chargingState = ChargingService.getChargingStatus;
    const chargeCode = ChargingService.getChargeCode;

    // console.log("chargingState");
    // console.log(chargingState);

    // console.log('chargeCode');
    // console.log(chargeCode);

    window.scrollTo(0, 0);

    if (!chargeCode){
      this.$router.push(`/${APP_ROUTE.chargeCode.path}`);
    } else {
      // console.log('charging');

      ChargingService.requestStatusData(chargeCode);

      this.timerId = setInterval(()=> {
        ChargingService.requestStatusData(chargeCode);
      }, 1000)
      ChargingService.requestChargeToData();
    }
  },

  computed: {
    errorMessageChargingStatus: () => {
      return ChargingErrorMessageService.messageState.type === HowToShowError.redText
        ? ChargingErrorMessageService.messageState.message
        : "";
    },
    chargingStateIsNull(){
      // console.log(' ');
      // console.log('----');
      // console.log('%c charging status', 'color:tomato');
      // console.log(ChargingService.getChargingStatus);

      return ChargingService.getChargingStatus === null;
    },
    getChargingInitAnimationValue(){
      return ChargingService.getChargingInitAnimationValue;
    },
    chargingStateIsInitializing() {
      return ChargingService.getChargingStatus === 'Init';
    },
    chargeStateIsChargingAndNoStopping() {
      return ChargingService.getChargingStatus === 'Charging' && !ChargingService.getState.stopChargingTransactionPending;
    },
    chargingStateIsCharging() {
      return ChargingService.getChargingStatus === 'Charging';
    },
    chargingStateIsStopping() {
      return ChargingService.getState.stopChargingTransactionPending;
    },
    showStopChargingBtn() {
      return ChargingService.getChargingStatus === "Charging";
    },
    chargeToPending() {
      return ChargingService.getState.chargeToPending;
    },
    getChargeUntil() {
      // console.log("%c getChargeUntil", "color:blue");
      // console.log(ChargingService.getChargeUntil);
      return ChargingService.getChargeUntil;
    },
    getTotalDelivered() {
      return ChargingService.getTotalDelivered;
    },
    getElapsedTime() {
      return ChargingService.getElapsedTimeFormatted || "00:00";
    },
    getUnitName() {
      return (ChargingService.getUnitData || {}).UnitName || "Unit Name";
    },
    getUnitId() {
      return (ChargingService.getUnitData || {}).UnitId;
    },
    getMeterValue() {
      return ChargingService.getMeterValue || 0;
    },
  },
  methods: {
    stopCharging() {
      ChargingService.stopChargingRequest();
    },
    chargeUntilChanged(value: any) {

      if (value < 50){
        return;
      }
      ChargingService.updateChargeToRequest(value);
    },
  },
  beforeDestroy() {
    // console.log("beforeDestroy charging vue");
    clearInterval(this.timerId);
    ChargingErrorMessageService.close();
  },
});
