import { Constants, MessageType } from "@/constants";
import i18n from "@/i18n";
import {
  AppMessageService,
  AppMessageServiceState,
} from "@/utils/alert/alertService";
import Vue from "vue";

const initState: AppMessageServiceState = {
  type: null,
  message: null,
  title: null,
};

const state = Vue.observable(initState);

export const ChargingErrorMessageService = {
  get messageState() {
    return state;
  },
  showError(message: string | null, typeOfError?: MessageType) {

    if (message === Constants.ErrNetwork) {
      state.message = i18n.t(message).toString();
      state.type = "redText";
    } else {
      AppMessageService.showError(message, typeOfError);
    }
  },
  close() {
    state.message = null;
    state.type = null;
    state.title = null;
  },
};
