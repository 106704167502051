let gSelectedLanguage = 0;

export function SetLanguage(value: number) {
  gSelectedLanguage = value;
}
export function GetLanguage() {
  return gSelectedLanguage;
}

export function GetDataSourceURL(): string {
  //return "https://localhost:7275";
  //return "https://elview.eldrift.no:5011";
  return process.env.VUE_APP_MAIN_URL;
}
