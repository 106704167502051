/* eslint-disable prettier/prettier */
import i18n from "@/i18n";
import { AppMessageService } from "@/utils/alert/alertService";
import { Device } from "@capacitor/device";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import OneSignal from "onesignal-cordova-plugin";

const oneSignalServ = () => {

  const apikey = "e392f267-b3c1-4dbc-b9de-2a841a953868";

  const initOneSignal = () => {
      // Uncomment to set OneSignal device logging to VERBOSE
      OneSignal.setLogLevel(6, 0);
    
      OneSignal.setAppId(apikey);
    
      // notification recevied
      OneSignal.setNotificationOpenedHandler(function (jsonData) {
        // console.log("notification");
        // console.log(jsonData);
        // console.log(JSON.stringify(jsonData));
      });

      OneSignal.setNotificationWillShowInForegroundHandler((event)=> {
        // console.log('setNotificationWillShowInForegroundHandler');
        // console.log(event);
        const notification = event.getNotification();
        // console.log("notification: ", notification);
        const data = notification.additionalData
        // console.log("additionalData: ", data);
        // Complete with null means don't show a notification.
        event.complete(notification);
      })

  }

  const promptForPushNotifications = async (func:any) => {
     // Prompts the user for notification permissions.

     const deviceInfo = await Device.getInfo();

     OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      
      if (deviceInfo.platform === 'android') {
        const pushNotification = i18n.t("PushNotifications").toString();

        if (!accepted) {
          const c = confirm(pushNotification);
          if (c) {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails, 
              optionIOS: IOSSettings.App
            });
  
            if (typeof func === 'function'){
              func();
            }

            return;
          }
        }

      }
  
      if (typeof func === 'function'){
        func();
      }
     
    });
  }

  const configureExternalUserId = (externalUserId: string) => {

    // Setting External User Id with Callback Available in SDK Version 2.11.2+
      
      OneSignal.setExternalUserId(externalUserId, (results:any) => {
      // The results will contain push and email success statuses
      // console.log("Results of setting external user id");
      // console.log(results);

      const result = results || {};

      // Push can be expected in almost every situation with a success status, but
      // as a pre-caution its good to verify it exists
      if (!result.push || !result.push.success) {
        // console.log('could notsent user id to OneSignal ');
        AppMessageService.showError('PushNotificationExternalUserIdIsNotSent');
      }

    });
  };

  const unbindExternalUserId = () => {
    // Remove External User Id with Callback Available in SDK Version 2.9.0+
    OneSignal.removeExternalUserId();
  }

  return {
    initOneSignal,
    promptForPushNotifications,
    configureExternalUserId,
    unbindExternalUserId
  };
};

// push notifications
export const OneSignalService = oneSignalServ();
