import { Constants, getAppErrorCodeType } from "@/constants";
import i18n from "@/i18n";
import router, { APP_ROUTE } from "@/router";
import {
  AlertHelperReturn,
  AppMessageService,
} from "@/utils/alert/alertService";
import delay from "delay";
import axios from "./interceptor";
import appCenter, {TrackableErrorModel, ExceptionModelType} from '@capacitor-community/appcenter-crashes';
import  appCenterEvents  from "@capacitor-community/appcenter-analytics";
import { GetDataSourceURL } from "@/global/variables";

export interface HttpHelperProps {
  url: string;
  method?: "POST" | "GET" | "DELETE";
  data?: any;
  showFeedbackText?: boolean;
  showErrorText?: boolean;
  showErrorTextIfAppError?: boolean;
  alertHelpers?: AlertHelperReturn;
  withDelay?: number;
}

const httpHelper = () => {
  const getResultData = (res: any) => {
    return res && res.data ? res.data : null;
  };

  const getAppErrorCode = (res: any) => {
    const resData = getResultData(res);
    if (!resData) {
      return null;
    }
    const errorCode = resData.AppErrorCode;
    return errorCode;
  };

  const getFeedbackErrorText = (res: any) => {
    const resData = getResultData(res);
    if (!resData) {
      return null;
    }
    const feedback = resData.AppErrorCode
      ? resData.AppErrorCode
      : resData.FeedbackText || i18n.t("UnknownError").toString();
    return feedback;
  };

  const httpRequestHandler = async (args: HttpHelperProps): Promise<any> => {
    const d = args.withDelay;

    if (!d) {
      return httpRequest(args);
    }

    await delay(d);

    return httpRequest(args);
  };

  const httpRequest = (args: HttpHelperProps): Promise<any> => {
    const {
      method = "GET",
      url,
      data,
      showFeedbackText = false,
      showErrorTextIfAppError = true,
      alertHelpers = AppMessageService,
    } = args;

    return axios({
      method: method ? method : "GET",
      url: url,
      data: method === "POST" ? data : null,
    })
      .then((res: any) => {
        const resData = getResultData(res);

        // no errors
        if (!resData.ErrorStatus) {
          const dataList =
            resData && resData.DataList ? resData.DataList : null;

          if (showFeedbackText && alertHelpers.showSuccess) {
            const feedback = (resData || {}).FeedbackText || null;
            alertHelpers.showSuccess(feedback);
          } else {
            alertHelpers.close();
          }

          return Promise.resolve({ dataList, result: res });
        } else {
          throw res;
        }
      })
      .catch((err: any) => {
        // console.log("httpRequestHandler catch");
        // console.log(err);

        const status = ((err || {}).response || {}).status || 0;
        const axErrorMessage = (err || {}).code;

        try {
          const statusText = (err || {}).statusText || '';
          const mess = (err || {}).message || "";
          const readyState = ((err || {}).request || {}).readyState || '';
          const dataFromServer = (err || {}).data || null;
          const currentDateTime = new Date();
          const formattedDateTime = currentDateTime.toLocaleString('en-US');

          appCenterEvents.trackEvent({
            name: "httpRequest problem: " + formattedDateTime,
            properties: {
              baseUrl: GetDataSourceURL(),
              url: url,
              requestReadyState: readyState + "",
              status: status + "",
              statusText: statusText + "",
              message: mess,
              feedbackDataFromServer: dataFromServer
                ? JSON.stringify(dataFromServer)
                : "",
              code: axErrorMessage ? axErrorMessage + " " : ""
            }
          });
          
        } catch (error) {
          // console.log(error);
        }

        if (status === 0 && axErrorMessage === Constants.ErrNetwork) {
          //ERR_NETWORK means no internet or it is not able to connect to your server. Kindly make sure your local server is running and also check if the port is correct.
          alertHelpers.showError(Constants.ErrNetwork);

        }
        else if (status === 401) {
          router.push(`/${APP_ROUTE.login.path}`);
          throw err;
        } else if (status === 404) {
          const er404 = Constants.AppErrorCode.error404.name.toString();
          alertHelpers.showError(er404 as any);
        } else if (status === 500) {
          const errorDetails =
            err && err.response && err.response.data ? err.response.data : null;
          const errorMessage = Constants.AppErrorCode.error500.name.toString();
          const resultText = `${i18n.t(errorMessage)}. ${errorDetails ?? ""}`;
          alertHelpers.showError(resultText as any);
        } else {
          if (showErrorTextIfAppError === true) {
            // handle app errors
            const appErrorCode = HttpHelper.getAppErrorCode(err);

             if (appErrorCode === Constants.AppErrorCode.userNotRegistered.name){
              //redirect to register page.
              router.push(`/${APP_ROUTE.registerPage.path}`);
            }
            // handle all other errors
            else {
              let errorText = getFeedbackErrorText(err);
              const errorTypeObj = getAppErrorCodeType(errorText);

              const typeOfError =
                errorTypeObj && errorTypeObj.howToShow
                  ? errorTypeObj.howToShow
                  : null;

              errorText = errorText ?? 'errorAppErrorNoCode'

              alertHelpers.showError(errorText, typeOfError);
            }
          }
        }

        throw err;
      });
  };

  return {
    httpRequestHandler,
    getFeedbackErrorText,
    getResultData,
    getAppErrorCode,
  };
};

export const HttpHelper = httpHelper();
