import Vue from "vue";
import i18n from "@/i18n";
import { HowToShowError, MessageType } from "@/constants";

export interface AlertHelperProps {
  message: string;
}

export interface AlertHelperReturn {
  showError: (message: string | null, typeOfError?: MessageType) => void;
  showSuccess?: (args: AlertHelperProps) => void;
  close: () => void;
}

export interface AppMessageServiceState {
  type: MessageType;
  message: string | null;
  title?: string | null;
}


const initState: AppMessageServiceState = {
  type: null,
  message: null,
  title: null,
};

const state = Vue.observable(initState);

export const AppMessageService = {
  get messageState() {
    return state;
  },
  get getDialogTitle() {
    if (state.type == HowToShowError.error) {
      return i18n.t("DialogErrorTitle").toString();
    }

    return state.title;
  },
  showError(message: string | null, typeOfError?: MessageType) {
    message = message ?? i18n.t("UnknownError").toString();

    // console.log("pre message type");
    // console.log(state.message);

    if (message) {
      const mes = i18n.t(message);
      state.message = mes.toString();
      // console.log('new');
      // console.log(state.message);

      state.type = typeOfError || HowToShowError.error;
    }
  },
  showSuccess(message: string | null) {
    if (message) {
      const mes = i18n.t(message);
      state.message = mes.toString();
      state.type = "success";
    }
  },
  showMessage(message: string | null, title: string | null) {
    if (message) {
      const mes = i18n.t(message);
      state.message = mes.toString();
      state.type = "message";
      state.title = title || "";
    }
  },
  close() {
    state.message = null;
    state.type = null;
    state.title = null;
  },
};
