// /src/plugins/axios.js
import { Constants } from "@/constants";
import { GetDataSourceURL } from "@/global/variables";
import axios from "axios";
import { StorageService } from "../storage/storage";

const httpClient = axios.create({
  baseURL: GetDataSourceURL()
});

// doing something with the request
httpClient.interceptors.request.use(async (request: any) => {
  // do something with request meta data, configuration, etc
  // dont forget to return request object,
  // otherwise your app will get no answer

  const token = await StorageService.get(Constants.JWTTokenName);
  request.headers.Authorization = `Bearer ${token}`;
  request.headers["Content-Type"] = "application/json";
  request.headers["Access-Control-Allow-Origin"] = "*";

  /* In case need to pass config with custom params
   request.config = {
    ...(request.config ?? {}), // preserve a given request.config object
    start: Date.now(),
  };
  */

  // dont forget to return request object,
  // otherwise your app will get no answer
  return request;
});


export default httpClient;
