
import i18n from "@/i18n";
import { APP_ROUTE } from "@/router";
import { ChargingService } from "@/services/chargingService";
import { ChargingSummaryService } from "@/services/chargingSummaryService";
import Vue from "vue";

export default Vue.extend({
  name: "ChargingSummary",
  components: {
    spinner: () => import("../components/spinner.vue"),
    graphChart: () => import("../features/charging/graphChart.vue"),
  },
  async created() {
    // console.log("charging summary created");
    await ChargingService.getChargingDataFromStorage();

    const code = ChargingService.getChargeCode;
    // console.log("chargeCode");
    // console.log(code);
    // console.log(ChargingService.getChargingStatus);

    if (!code){
      this.$router.push(`/${APP_ROUTE.chargeCode.path}`);
    } else {
      ChargingSummaryService.requestResultData();
      ChargingSummaryService.requestGraphData();
    }
    window.scrollTo(0, 0);
  },
  computed: {
    getGraphData() {
      return ChargingSummaryService.getState.graphData;
    },
    getResultError() { 
      const tempMessage = (ChargingService.getState.chargingData || {}).ChargingErrorCode;
      const err = tempMessage? i18n.t(tempMessage): null;
      return err;
    },
    graphDataLoading() {
      return ChargingSummaryService.getState.graphDataLoading || ChargingSummaryService.getState.chargingSummaryLoading;
    },
    getUnitName() {
      return (ChargingService.getUnitData || {}).UnitName || "Unit name";
    },
    getUnitId() {
      return (ChargingService.getUnitData || {}).UnitId || "";
    },
    totalPrice() {
      return ChargingSummaryService.getPrice || "-";
    },
    priceUnit(){
      return ChargingSummaryService.getPriceUnit || "";
    },
    totalDelivered() {
      return ChargingSummaryService.getTotalDelivered || "-";
    },
    getElapsedTime() {
      return ChargingSummaryService.getElapsedTimeFormatted || "-";
    },
  },
  async beforeDestroy() {
    await ChargingService.resetChargingData();
    // console.log("resetChargingData before destroy");
    // console.log(JSON.stringify(ChargingService.getState));
    ChargingSummaryService.resetState();
  },
});
