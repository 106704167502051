import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: '#53B6B3', //Blue
        primary: '#357d7b', // Darker blue set for A11y
        secondary: '#69CA97', //Green
        accent: '#F6CC6A', //Yellow
        error: '#AEB6B6', // Grey
        warning: '#FF7064', //Red
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});

Vue.use(Vuetify);

export default vuetify;
