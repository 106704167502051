export interface AppNewUser {
  LastName: string | null;
  FirstName: string | null;
  EMail: string | null;
  Mobile: string | null;
  Address: string | null;
  PostCode: number | null;
  City: string | null;
  CountryID: number | null;
  AcceptEInvoice: boolean;
  AcceptedTerms: boolean;
  AcceptedPromotions: boolean;
  NewUserVerificationCode?: string;
}

export interface AppUser extends AppNewUser {
  AppUserID?: number | null;
  Username?: string | null;
  UserLevel?: string | null;
  Language?: string | null;
  DarkTheme?: string | null;
  CreatedDate?: string | null;
  Company?: string | null;
  PrivatPerson?: boolean;
  ChargeTo?: number | null;
}

/** State */
export interface UserAppState {
  user: AppUser | null;
  userSaving: boolean;
  userLoading: boolean;
  userDeleting: boolean;
  userCancelingChanges: boolean;
}

export const AppUserRole = {
  None: "Non",
  Admin: "Admin",
  CsOperator: "CS Operator",
  ElstoreAdmin: "Elstore admin",
  PublicAPI: "PublicAPI",
  Customer: "Customer",
  Support: "Support",
  ElchargeSuperuser: "Elcharge Superuser"
};

const ThemeColors = {
  primary: "#53B6B3", //Blue
  secondary: "#69CA97", //Green
  accent: "#F6CC6A", //Yellow
  error: "#AEB6B6", // Grey
  warning: "#FF7064", //Red
};
