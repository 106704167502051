
import { SecurityService } from "@/services/securityService";
import { LoginWithUserRequest } from "@/models/auth";
import { AppMessageService } from "@/utils/alert/alertService";
import { HowToShowError } from "@/constants";
import { ValidationService } from "@/utils/form/validationHelpers";
import Vue from "vue";

export default Vue.extend({  
  name: "loginPage",
  components: {},
  created() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      rules: ValidationService.getRules(),
    };
  },
  computed: {
    form() {
      return SecurityService.getSignInForm;
    },
    signingIn() {
      return SecurityService.userState.signingInWithEmail;
    },
    validationErrorFromServer: () => {
      return AppMessageService.messageState.type === HowToShowError.redText
        ? AppMessageService.messageState.message
        : "";
    },
  },
  methods: {
    onFieldChanged(value: any) {
      AppMessageService.close();
      SecurityService.setSignFormValue({ email: value });
    },
    login() {
      const formIsValid = (this.$refs.entryForm as Vue & { validate: () => boolean }).validate();

      //const formIsValid = ((this.$refs || {}).entryForm || {}).validate();
      if (!formIsValid) {
        return;
      }
      const email = (SecurityService.getSignInForm || {}).email as string;
      const data: LoginWithUserRequest = { email };
      SecurityService.loginWithEmail(data);
    },
  },
});
