import { Duration } from "luxon";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { DateTime } = require("luxon");

const defaultFormat = "dd LLLL yyyy, HH:mm ";

//DateTime.fromFormat("01/01/2021 " + currentState.toTime, "D hh:mm:ss");
const defaultTimeFormat = "HH:mm";
const localStrFormat = "HH:mm";

export const dateTimeHelper = {
  formatDate: (dateStr: string, format: string = defaultFormat): string => {
    if (!dateStr) return "";

    return DateTime.fromISO(dateStr).toFormat(format);
  },
  getTimeFromString: (
    timeStr: string,
    format: string = "D " + defaultTimeFormat
  ): Date | null => {
    if (!timeStr) return null;

    try {
      return DateTime.fromFormat("01/01/2021 " + timeStr, format).toJSDate();
    } catch (error) {
      // console.log("failed to parse time");
      // console.log(error);
      return null;
    }
  },
  getTimeFromSeconds(sec: number) {
    if (!sec) {
      return "";
    }

    const ms = sec * 1000;

    if (sec >= 3600) {
      return Duration.fromMillis(ms).toFormat("hh:mm:ss");
    } else {
      return Duration.fromMillis(ms).toFormat("mm:ss");
    }
  },   
  //format 2022-01-17T13:40:16.5900000Z with Z. UTC string must have .000Z
  getLocalFromUtcStr: (timeAsStr: string): any => {

    if (!timeAsStr || timeAsStr === "" || timeAsStr === "0001-01-01T00:00:00")
    {
        return null;
    }

    let result = DateTime.fromISO(timeAsStr);
    result = result || "";
    return result;

},
  getLocalFromUtcStrFormated: (
    timeAsStr: string,
    format = localStrFormat
  ): string | null => {
    if (!timeAsStr) {
      return null;
    }

    const result = dateTimeHelper.getLocalFromUtcStr(timeAsStr);

    if (!result) {
      return null;
    } else {
      return result.toFormat(format);
    }
  },
};
