import i18n from "@/i18n";

const validationHelper = () => {
  const getRules = () => {
    return {
      /*isNumber: (value: any ) => {
        let number = value;
        if (typeof value === 'string'){
          number = Number(value);
        }

        return Number.isInteger(number) || "Th number";
     },
     */
      sliderMinValue: (value: any) => (value < 50 ? i18n.t("ValidationErrorChargeUntilMinValue") : true),
      checkboxTrueFalse: (v: any) =>
        v == true || i18n.t("ValidationErrorCheckboxTrueRequired"),
      required: (value: any) =>
        !value ? i18n.t("ValidationErrorRequired") : true,
        
      isNumber: (value:any)=> isNaN(value) ? i18n.t("ValidationNotANumber") : true ,
      email: (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || i18n.t("ValidationErrorInvalidEmail");
      },
    };
  };

  return {
    getRules,
  };
};

export const ValidationService = validationHelper();
