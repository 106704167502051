import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import loginPage from "../views/LoginPage.vue";
import verificationPage from "../views/VerificationPage.vue";
import ChargeCode from "../views/ChargeCode.vue";
import ChargingPage from "../views/Charging.vue";
import NewUser from "../views/NewUser.vue";
import ProfilePage from "../views/ProfilePage.vue";
import Support from "../views/Help.vue";
import ChargingSummary from "../views/ChargingSummary.vue";
import ChargeCodeWithInput from "../views/ChargeCodeWithInput.vue";
import settings from "../views/Settings.vue";

Vue.use(VueRouter);

export type AppRouteName = "readqr" | "support" | "profile";

//TODO: use for all routes.
export const APP_ROUTE = {
  loginOld: { path: "loginOld", name: "LoginOld" }, //old
  login: { path: "login", name: "Login" },
  verificationPage: { path: "verification", name: "Verification" },
  registerPage: { path: "newUser", name: "NewUser" },
  chargingOld: { path: "readqr", name: "readqr" },
  support: { path: "support", name: "Support" },
  profile: { path: "profile", name: "Profile" },
  chargeCode: { path: "chargeCode", name: "ChargeCode" },
  chargeCodeWithInput: {path: "chargeCodeWithInput", name: "ChargeCodeWithInput"  },
  charging: { path: "charging", name: "Charging" },
  chargingSummary: { path: "chargingSummary", name: "ChargingSummary" },
  settings: { path: "settings", name: "Settings" },
};

const routes: Array<RouteConfig> = [
  {
    path: `/`,
    alias: [`/${APP_ROUTE.chargeCode.path}`],
    name: APP_ROUTE.chargeCode.name,
    component: ChargeCode,
  },
  {
    path: `/${APP_ROUTE.chargeCodeWithInput.path}`,
    name: APP_ROUTE.chargeCodeWithInput.name,
    component: ChargeCodeWithInput,
  },
  {
    path: `/${APP_ROUTE.charging.path}`,
    name: APP_ROUTE.charging.name,
    component: ChargingPage,
  },
  {
    path: `/${APP_ROUTE.chargingSummary.path}`,
    name: APP_ROUTE.chargingSummary.name,
    component: ChargingSummary,
  },
  {
    path: `/${APP_ROUTE.registerPage.path}`,
    name: APP_ROUTE.registerPage.name,
    component: NewUser,
  },
  {
    path: `/${APP_ROUTE.profile.path}`,
    name: APP_ROUTE.profile.name,
    component: ProfilePage,
  },
  {
    path: `/${APP_ROUTE.support.path}`,
    name: APP_ROUTE.support.name,
    component: Support,
  },

  {
    path: `/${APP_ROUTE.verificationPage.path}`,
    name: APP_ROUTE.verificationPage.name,
    component: verificationPage,
  },
  {
    path: `/${APP_ROUTE.login.path}`,
    name: APP_ROUTE.login.name,
    component: loginPage,
  },
  {
    path: `/${APP_ROUTE.settings.path}`,
    name: APP_ROUTE.settings.name,
    component: settings,
  },
  //old routes
  {
    path: "/Logs",
    name: "Logs",
    // route level code-splitting
    // this generates a separate chunk (Logs.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Logs" */ "../views/Logs.vue"),
  },

  {
    path: "/LogDetails",
    name: "LogDetails",
    // route level code-splitting
    // this generates a separate chunk (LogDetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "LogDetails" */ "../views/LogDetails.vue"),
  },

  {
    path: "/PortableCharger",
    name: "PortableCharger",
    // route level code-splitting
    // this generates a separate chunk (PortableCharger.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PortableCharger" */ "../views/PortableCharger.vue"
      ),
  },
  {
    path: "/ELStore",
    name: "ELStore",
    // route level code-splitting
    // this generates a separate chunk (ELStore.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ELStore" */ "../views/ELStore.vue"
      ),
  },
  {
    path: "/Auth2Factor",
    name: "Auth2Factor",
    // route level code-splitting
    // this generates a separate chunk (Auth2Factor.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Auth2Factor" */ "../views/Auth2Factor.vue"
      ),
  },

  


];

export const goToMainPage = () => {
  router.push(`/${APP_ROUTE.chargeCode.path}`);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
