import Vue from "vue";
import App from "./App.vue";
import { App as CapacitorApp } from "@capacitor/app";

import router from "./router";
//import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import i18n from "./i18n";

Vue.config.productionTip = false;

new Vue({
  router,
  //store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if(canGoBack){
    window.history.back();
  } else {
    CapacitorApp.exitApp();
  }
});
