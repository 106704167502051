var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:'elview-app ' + 'elview-app-' + _vm.lang},[(
      ![
        _vm.getAppRoute.login.name,
        _vm.getAppRoute.registerPage.name,
        _vm.getAppRoute.verificationPage.name,
      ].includes(_vm.$route.name)
    )?_c('div',{staticClass:"app-header-without-logo"}):_vm._e(),_c('v-main',[(!_vm.getUserIsLoading)?_c('v-container',{class:'main-container ' + ' page-' + _vm.$route.name},[_c('router-view')],1):_vm._e(),(_vm.getUserIsLoading)?_c('v-container',{staticClass:"app-loading"},[_c('v-progress-circular',{attrs:{"size":80,"color":"primary","indeterminate":""}})],1):_vm._e()],1),(
      ![
        _vm.getAppRoute.login.name,
        _vm.getAppRoute.registerPage.name,
        _vm.getAppRoute.verificationPage.name,
        'LoginOld',
        'Home',
        'Verification',
      ].includes(_vm.$route.name)
    )?_c('div',{staticClass:"with-tab-footer",attrs:{"app":""}},[_c('ElviewBack')],1):_vm._e(),_c('messageDialog',{attrs:{"showAppMessage":_vm.showAppMessage,"dialogTitle":_vm.dialogTitle,"dialogMessage":_vm.dialogMessage},on:{"onCloseOutside":_vm.closeDialog,"onCloseDialog":_vm.closeDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }