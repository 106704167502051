import i18n from "@/i18n";
import { QrCodeScannerState } from "@/models/qrCodeScanner";
import router, { APP_ROUTE, goToMainPage } from "@/router";
import { AppMessageService } from "@/utils/alert/alertService";
import {
  BarcodeScanner,
  ScanResult,
  SupportedFormat,
} from "@capacitor-community/barcode-scanner";
import Swal from "sweetalert2";
import Vue from "vue";

const initState: QrCodeScannerState = {
  result: { hasContent: false, content: "" },
  qrCodeScanning: false,
  retry: false,
  retrying: false,
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const QRCodeScannerService = {
  get isRetry() {
    return state.retry;
  },
  get isRetrying() {
    return state.retrying;
  },
  setIsRetryToActive() {
    state.retry = true;
  },
  setIsRetryingToActive() {
    state.retrying = true;
  },

  /** doc: https://github.com/capacitor-community/barcode-scanner/tree/73db101b9a62bc31595f469ad4c5c4cc772fdc1b*/
  /** A more detailed and more UX-optimized example, from docs. */
  didUserGrantPermission: async () => {
    // check if user already granted permission
    let status = null;
    try {
      status = await BarcodeScanner.checkPermission({ force: false });
    } catch (err: any) {
      // console.log(err);
      AppMessageService.showError(err.message, "redText");
      return false;
    }

    if (status?.granted) {
      // user granted permission
      // console.log("qr scanning granted");
      return true;
    }

    if (status?.denied) {
      // user denied permission
      return false;
    }

    if (status?.neverAsked) {
      // console.log("never asked");

      const text = i18n.t("QRScanWeNeedYourPermission").toString();

      const confirmResult: any = await Swal.fire({
        html: '<span class="confirm-text" >' + text + "</span>",
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          buttonsStyling: false,
          customClass: 'confirm-container',
          showClass: {
            popup: 'swal2-noanimation'
          },
          confirmButtonText:
            '<button type="button" ' +
            ' class="v-btn v-btn--is-elevated v-btn--has-bg v-size--x-large primary confirm-btn-style ">' +
            '<span class="v-btn__content"> ' +
            i18n.t("Continue") +
            " </span> </button>",
          backdrop: true,
          background: "#fff",
          allowOutsideClick: false,
        });
  
        if (confirmResult && confirmResult.isConfirm === false) {
          return false;
        }
    
    }

    if (status?.restricted || status?.unknown) {
      // console.log("restricted || unknown");
      // ios only
      // probably means the permission has been denied
      return false;
    }

    // user has not denied permission
    // but the user also has not yet granted the permission
    // so request it
    const statusRequest = await BarcodeScanner.checkPermission({
      force: true,
    });

    // console.log("status request force true");

    if (statusRequest.granted) {
      // the user did grant the permission now
      // console.log("granted");
      return true;
    }

    return false;
  },
  startScan(): Promise<any> {
    state.retry = false;
    state.result.hasContent = false;
    //BarcodeScanner.hideBackground(); // make background of WebView transparent
    state.qrCodeScanning = true;
    AppMessageService.close();

    return BarcodeScanner.startScan({
      targetedFormats: [SupportedFormat.QR_CODE],
    })
      .then((result: ScanResult) => {
        state.result.hasContent = result.hasContent;
        state.result.content = (result.content || "").toString();

        if (state.result.hasContent) {
          return Promise.resolve(state.result.content);
        } else {
          AppMessageService.showError("StartScan - no content", "redText");
          state.retry = true;
          return Promise.reject();
        }
      })
      .catch((err) => {
        state.retry = true;
        AppMessageService.showError(
          `There was an error in BarcodeScanner.startScan. See details: ${err}`,
          "redText"
        );

        return Promise.reject();
      })
      .finally(() => {
        state.qrCodeScanning = false;
        state.retrying = false;
      });
  },
  prepareScan() {
    BarcodeScanner.prepare();
  },
  hideBg(){
    const color = 'rgba(255, 255, 255, 0.74)';
    this.changeAppBgColor(color);

  },
  showBg(){
    const color = "white";
    this.changeAppBgColor(color);
  },

  changeAppBgColor(color: "rgba(255, 255, 255, 0.74)" | "white") {

    try {
      const mainComp: any = document.getElementsByClassName("elview-app");
      const body = document.body;

     // mainComp[0].style.backgroundColor = 'white';
      body.style.backgroundColor = color;
    } catch (error) {
      // console.log(error);
    }
  },
  stopScan() {
    if (!state.qrCodeScanning) {
      return;
    }

    try {
      //BarcodeScanner.showBackground();
      this.showBg();
      BarcodeScanner.stopScan();
      state.qrCodeScanning = false;
    } catch (err: any) {
      AppMessageService.showError(err.message);
      state.qrCodeScanning = false;
  
    }
  },
};
