
import { SecurityService } from "@/services/securityService";
import { LoginWithCodeRequest } from "@/models/auth";
import { AppMessageService } from "@/utils/alert/alertService";
import { HowToShowError } from "@/constants";
import i18n from "@/i18n";
import router, { APP_ROUTE } from "@/router";

export default {
  name: "verificationPage",
  components: {},
  created() {

    window.scrollTo(0, 0);

   const email = (SecurityService.getSignInForm || {}).email;
      if (!email) {
        router.push(`/${APP_ROUTE.login.path}`);
      }
  },
  data() {
    return {
      rules: {
        required: (value: any) =>
          !value ? i18n.t("ValidationErrorRequired") : true,
        errorFromServer: () => {
          return AppMessageService.messageState.type === HowToShowError.redText
            ? AppMessageService.messageState.message
            : true;
        },
      },
    };
  },
  computed: {
    form() {
      return SecurityService.getSignInForm;
    },
    signingInWithCode() {
      return SecurityService.userState.signingInWithCode;
    },
    validationErrorFromServer: () => {
      return AppMessageService.messageState.type === HowToShowError.redText
        ? AppMessageService.messageState.message
        : "";
    },
  },
  methods: {
    onFieldChanged(value: any) {
      const email = (SecurityService.getSignInForm || {}).email as string;
      SecurityService.setSignFormValue({ code: value, email });
    },
    verify() {
      const email = (SecurityService.getSignInForm || {}).email as string;
      const code = (SecurityService.getSignInForm || {}).code as string;

      if (!email) {
        return;
      }

      const data: LoginWithCodeRequest = {
        email,
        code,
      };

      SecurityService.loginWithCode(data);
    },
  },
};
