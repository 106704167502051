
import { APP_ROUTE } from "@/router";
import { ChargingService } from "@/services/chargingService";
import { QRCodeScannerService } from "@/services/qrCodeScannerService";
import { AppMessageService } from "@/utils/alert/alertService";
import { ValidationService } from "@/utils/form/validationHelpers";
import Vue from "vue";

export default Vue.extend({
  name: "ChargeCodeWithInput",
  components: {
    spinner: () => import("../components/spinner.vue"),
    errorMessage: () => import("../components/errorMessage.vue"),
  },

  data() {
    return {
      rules: ValidationService.getRules(),
    };
  },
  async created() {
    AppMessageService.close();
    await ChargingService.getChargingDataFromStorage();

    window.scrollTo(0, 0);
  },
  computed: {
    statusDataLoading() {
      return ChargingService.getState.statusDataLoading;
    },

    chargeCode() {
      return ChargingService.getPointConnectorCode;
    },
    startChargingPending() {
      return (
        ChargingService.getState.setCodePending ||
        ChargingService.getState.startChargingTransactionPending
      );
    },
  },
  methods: {
    goToQrCodeScanner() {
      this.$router.push(`/${APP_ROUTE.chargeCode.path}`);
    },
    onFieldChanged(value: any) {
      ChargingService.setChargingCode(value);
      AppMessageService.close();
    },
    startCharging() {
      const formIsValid = (
        this.$refs.chargingCodeForm as Vue & { validate: () => boolean }
      ).validate();

      if (!formIsValid) {
        return;
      }

      const value = ChargingService.getPointConnectorCode;

      if (!value) {
        AppMessageService.showError("ClientErrorNoChargingCode");
      }

      ChargingService.requestSetCode({ newCode: value });
    },
  },
  beforeDestroy() {
    QRCodeScannerService.stopScan();
  }
});
