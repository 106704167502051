import { Constants } from "@/constants";
import {
  AppAuthUser,
  AppSignInFormState,
  LoginUserRequest,
  LoginWithCodeRequest,
  LoginWithUserRequest,
  SignInWithEmail,
} from "@/models/auth";
import { ResponseData } from "@/models/common";
import router, { APP_ROUTE, goToMainPage } from "@/router";
import { AppMessageService } from "@/utils/alert/alertService";
import { HttpHelper } from "@/utils/http/httpHelper";
import { StorageService } from "@/utils/storage/storage";
import jwtDecode from "jwt-decode";
import Vue from "vue";
import { OneSignalService } from "./oneSignalService";

const initState: AppSignInFormState = {
  user: null,
  loading: true,
  signingIn: false, //old page to delete!!!!
  signInForm: {
    email: null,
    code: null,
    extra: null, // returned when code is verified
  },
  signingInWithEmail: false,
  signingInWithCode: false,
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const SecurityService = {
  get userState() {
    return state;
  },
  get getUser() {
    return state.user;
  },
  get getSignInForm() {
    return state.signInForm;
  },
  setUserData(userData: any) {
    state.user = userData;
  },
  setUserLoading(value = false) {
    state.loading = value;
  },
  setSignFormValue(value: SignInWithEmail) {
    state.signInForm = value;
  },
  clearVerificationCode() {
    state.signInForm.code = null;
  },
  clearSignInForm() {
    state.signInForm = {
      email: null,
      code: null,
      extra: null,
    };
  },
  async getToken() {
    const token = await StorageService.get(Constants.JWTTokenName);
    return token;
  },
  setTokenAndUserData(resultData: any) {
    const token = resultData.token;
    resultData.token = null;

    StorageService.set(Constants.JWTTokenName, token);
    StorageService.set(Constants.JWTTokenUserData, resultData, true);
    this.setUserData(resultData);
  },
  async authUser(redirectFunc: any) {
    try {
      const token = await this.getToken();

      if (!token){

        redirectFunc();
        Promise.reject();

      }

      const parsedData: any = token ? jwtDecode(token) : null;

      const userDataFromStorage = await StorageService.get(
        Constants.JWTTokenUserData,
        true
      );

      this.setUserData(userDataFromStorage);

      if (state.user) {
        state.user.UserName = parsedData.unique_name;
      }
      state.loading = false;
    } catch (error) {
      state.loading = false;
      redirectFunc();
      Promise.reject();
    }
  },

  async login(loginUser: LoginUserRequest) {
    state.signingIn = true;

    return HttpHelper.httpRequestHandler({
      url: "/auth/auth",
      method: "POST",
      data: loginUser,
    })
      .then((res) => {
        const resultData = res.dataList;
        this.setTokenAndUserData(resultData);
      })
      .finally(() => {
        state.signingIn = false;
      });
  },

  async loginWithEmail(loginData: LoginWithUserRequest) {
    state.signingInWithEmail = true;

    return HttpHelper.httpRequestHandler({
      url: "/App_Control/loginWithEmail",
      method: "POST",
      data: loginData,
    })
      .then((x) => {
        router.push(`/${APP_ROUTE.verificationPage.path}`);
      })
      .finally(() => {
        state.signingInWithEmail = false;

      });
  },

  async loginWithCode(loginData: LoginWithCodeRequest) {
    state.signingInWithCode = true;

    return HttpHelper.httpRequestHandler({
      url: "/App_Control/verifyAuthCode",
      method: "POST",
      data: loginData,
    })
      .then((res: ResponseData) => {
        const resultData = res.dataList as AppAuthUser;
        const userId = resultData ? resultData.UserID : null;
        this.setTokenAndUserData(resultData);
        // console.log("resultData");
        // console.log(userId);
        // console.log(process.env.NODE_ENV);

        if (process.env.NODE_ENV !== "development") {
          if (!userId) {
            AppMessageService.showError(
              "PushNotificationExternalUserIdIsNotSent"
            );
            throw "Push notification external user is not configured";
          }

          OneSignalService.configureExternalUserId(userId.toString());

          OneSignalService.promptForPushNotifications(() => {
            goToMainPage();
          });
        } else {
          goToMainPage();
        }
      })
      .catch((res) => {
        const dataFromServer = res && res.data ? res.data : null;

        if (dataFromServer && dataFromServer.ekstra) {
          state.signInForm.extra = dataFromServer.ekstra;
        }

      })
      .finally(() => {
        state.signingInWithCode = false;
        this.clearVerificationCode();
      });
  },

  async logout() {
    try {
      await StorageService.removeStorageItem(Constants.JWTTokenName);
      await StorageService.removeStorageItem(Constants.JWTTokenUserData);
      OneSignalService.unbindExternalUserId();
    } catch (error: any) {
      AppMessageService.showError(error.message);
    }
    router.push(`/${APP_ROUTE.login.path}`);
  },
};
