import { ChargingSummaryDataState } from "@/models/charging";
import { ResponseData } from "@/models/common";
import { AppMessageService } from "@/utils/alert/alertService";
import { HttpHelper } from "@/utils/http/httpHelper";
import { dateTimeHelper } from "@/utils/types/dateTime";
import Vue from "vue";
import { ChargingService } from "./chargingService";

const initState: ChargingSummaryDataState = {
  graphData: [],
  graphDataLoading: true,
  chargingSummaryLoading: true,
  chargingSummary: {
    TotalTime: 0,
    TotalPrice: 0,
    TotalDelivered: 0,
    PriceUnit: "",
  },
};

// Make your state a private variable only available to your current file (module)
const state = Vue.observable(initState);

export const ChargingSummaryService = {
  get getState() {
    return state;
  },

  get getChargingSummary() {
    return state.chargingSummary;
  },

  setGrafData() {
    state.graphData = [{
      "TimeStamp": "2022-01-17T07:07:00.0000000Z",
        SoC: 65.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:08:00.0000000Z",
      "SoC": 66.0,
      "W": 20000.0
    },
   
    {
      "TimeStamp": "2022-01-17T07:10:00.0000000Z",
      "SoC": 67.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:08:00.0000000Z",
      "SoC": 68.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:13:30.0000000Z",
      "SoC": 69.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:14:30.0000000Z",
      "SoC": 70.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:15:30.0000000Z",
      "SoC": 71.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:18:30.0000000Z",
      "SoC": 72.0,
      "W": 20000.0
    },
   
    {
      "TimeStamp": "2022-01-17T07:20:30.0000000Z",
      "SoC": 73.0,
      "W": 20000.0
    },
      
    {
      "TimeStamp": "2022-01-17T07:26:30.0000000Z",
      "SoC": 74.0,
      "W": 20000.0
    },
   
    {
      "TimeStamp": "2022-01-17T07:32:30.0000000Z",
      "SoC": 75.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:32:30.0000000Z",
      "SoC": 76.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:32:30.0000000Z",
      "SoC": 77.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:38:30.0000000Z",
      "SoC": 78.0,
      "W": 20000.0
    },
   
    {
      "TimeStamp": "2022-01-17T07:44:30.0000000Z",
      "SoC": 79.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:50:30.0000000Z",
      "SoC": 80.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:57:30.0000000Z",
      "SoC": 81.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T07:59:30.0000000Z",
      "SoC": 82.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T08:03:30.0000000Z",
      "SoC": 83.0,
      "W": 20000.0
    },
     
    {
      "TimeStamp": "2022-01-17T08:03:30.0000000Z",
      "SoC": 84.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T08:09:30.0000000Z",
      "SoC": 85.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T08:09:30.0000000Z",
      "SoC": 86.0,
      "W": 20000.0
    },
    {
      "TimeStamp": "2022-01-17T15:10:30.0000000Z",
      "SoC": 87.0,
      "W": 22000.0
    }];
  },

  get getElapsedTime(): number {
    return (state.chargingSummary || {}).TotalTime || 0;
  },
  get getTotalDelivered(): number {
    //TotalDelivered er i Wh, skal du ha kWh så må du dele på 1000
    const value = (state.chargingSummary || {}).TotalDelivered;
    try {
      const kWH = value && value != 0 ? value / 1000 : 0;
      const kWHRounded = Math.round(kWH);
      return kWHRounded;
    } catch (err) {
      AppMessageService.showError(
        `Could not convert value: ${value} from Wh to kWH. Error message: ${err}`
      );
      return 0;
    }
  },

  get getPrice() {
    return (state.chargingSummary || {}).TotalPrice || "";
  },
  get getPriceUnit() {
    return (state.chargingSummary || {}).PriceUnit || "";
  },

  get getElapsedTimeFormatted(): string {
    const time = this.getElapsedTime
      ? dateTimeHelper.getTimeFromSeconds(this.getElapsedTime) || "-"
      : "";
    return time;
  },

  async requestGraphData() {
    state.graphDataLoading = true;
    const code = (ChargingService.getUnitData || {}).chargeCode;
    if (!code) {
      AppMessageService.showError("ClientErrorNoChargingCode", "redText");
    }

    return HttpHelper.httpRequestHandler({
      url: `/GetGraphData?ChargeCode=${code}`,
      withDelay: 1000,
    })
      .then((res: ResponseData) => {
        state.graphData = res.dataList;
      })
      .finally(() => {
        state.graphDataLoading = false;
      });
  },

  async requestResultData() {
    const code = (ChargingService.getUnitData || {}).chargeCode;
    if (!code) {
      AppMessageService.showError("ClientErrorNoChargingCode", "redText");
    }

    state.chargingSummaryLoading = true;

    return HttpHelper.httpRequestHandler({
      url: `/GetChargingResultData?ChargeCode=${code}`,
      withDelay: 1000,
    })
      .then((res: ResponseData) => {
        // console.log("res");
        // console.log(res);
        if (res.dataList && res.dataList.length > 0) {
          state.chargingSummary = res.dataList[0];
        }
      })
      .finally(() => {
        state.chargingSummaryLoading = false;
      });
  },

  resetState() {
    state.graphData = [];
    state.graphDataLoading = true;
    state.chargingSummaryLoading = true;
    state.chargingSummary = {
      TotalTime: 0,
      TotalPrice: 0,
      TotalDelivered: 0,
      PriceUnit: "",
    };
  },
};
