
import Vue from "vue";
import { SecurityService } from "@/services/securityService";
import router, { APP_ROUTE } from "@/router";

export default Vue.extend({
  name: "NewUser",
  data() {
    return {
      dialogIsOpen: false,
    };
  },
  components: {
    newUserForm: () => import("../features/user/newUserForm.vue"),
  },
  created () {
    const email = (SecurityService.getSignInForm || {}).email;
    if (!email) {
      router.push(`/${APP_ROUTE.login.path}`);
    }

    window.scrollTo(0, 0);
  },
  destroyed() {
    SecurityService.clearSignInForm();
  },
  methods: {
   /* showDialog() {
      this.dialogIsOpen = true;
    },
    closeDialog: function () {
      this.dialogIsOpen = false;
    },
    */
  },
});
