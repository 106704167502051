
import Vue from "vue";
import { SecurityService } from "./services/securityService";
import { AppMessageService } from "./utils/alert/alertService";
import { APP_ROUTE } from "./router";
import { HowToShowError } from "./constants";
import { CountryService } from "./services/countryService";
import { Device } from "@capacitor/device";
import { OneSignalService } from "./services/oneSignalService";
import appCenter from "@capacitor-community/appcenter-crashes";

export default Vue.extend({
  name: "App",
  data() {
    return {
      lang: "en",
    };
  },
  components: {
    ElviewBack: () => import("./components/ElviewBack.vue"),
    messageDialog: () => import("./components/messageDialog.vue"),
  },
  async created() {
    const languageCode = await Device.getLanguageCode();

    try {
      appCenter.setEnabled({ enable: true });
    } catch (error) {
      // console.log(error);
    }

    if (languageCode.value === "nb" || languageCode.value === "no") {
      this.$i18n.locale = "no";
      this.lang = "no";
    } else {
      this.$i18n.locale = "en";
    }

    if (
      this.$route.name == APP_ROUTE.login.name ||
      this.$route.name == APP_ROUTE.verificationPage.name ||
      this.$route.name == APP_ROUTE.registerPage.name
    ) {
      SecurityService.setUserLoading(false);
    } else {
      // console.log("not login page, do redirect");
      //get user data from local storage
      const redirectFunc = () => this.$router.push("/login");
      SecurityService.authUser(redirectFunc).catch(() => {
        SecurityService.setUserLoading(false);
      });

      document.addEventListener(
        "deviceready",
        OneSignalService.initOneSignal,
        false
      );
    }
    CountryService.requestCountries();
  },
  computed: {
    showAppMessage(): boolean {
      const messageType = AppMessageService.messageState.type;

      return messageType === HowToShowError.error ||
        messageType === HowToShowError.message
        ? true
        : false;
    },
    dialogMessage(): string {
      return AppMessageService.messageState.message ?? "";
    },
    dialogTitle(): string {
      return AppMessageService.getDialogTitle ?? "";
    },
    getUserIsLoading() {
      return SecurityService.userState.loading;
    },
    getAppRoute() {
      return APP_ROUTE;
    },
  },
  methods: {
    closeDialog: function () {
      AppMessageService.close();
    },
  },
});
